import mainImg from '../img/method-man-ghost-face.jpg';
export default function About(){
    return (
        <>
        <section className="bg-black">
            <div className="max-w-7xl mx-auto px-6 lg:px-0 py-8">
                <div className="grid lg:grid-cols-2 lg:gap-4 place-items-center">
                    <div className=''>
                        <img src={mainImg} alt="Method Man and Ghost Face" className="object-cover h-full w-full" />
                    </div>
                    <div className='mb-10 md:mb-0 text-left md:text-left py-12 px-6'>
                    <p className="text-white text-base mb-2"><span className="font-bold">Strictly Limited Edition:</span> 36 giant hardback coffee table books each encased in a striking bronze-encrusted black steel chamber. Each edition is signed, dated and numbered with certificates of authenticity provided. Only 36 are available for sale worldwide.</p>
                    <p className="text-white text-base mb-2"><span className="font-bold">The Book:</span> Large format 'coffee table' style photographic book (21" x 21" with over 300 colour pages) showcasing rare and unseen images of the band by leading photographers including the legendary Danny Hastings, PROTIM PHOTO, Kyle Christy and Andy Cantillon as well as friends and family of the Clan. Printed and bound in Italy using only the finest leather and materials, each of the 300 pages is a snapshot of Wu-Tang history in the making, providing a unique glimpse into the origins of the biggest Hip-Hop Crew of all time.</p>
                    <p className="text-white text-base mb-2"><span className="font-bold">The Chamber:</span> 400 lbs of steel and bronze each individually designed by the sculptor Gethin Jones (protégé of Antony Gormley). Inspired by the ancient past and the bronze ritual bowls of the Zhou Dynasty whose first ruler was King Wu-Wang, the chambers consist of Spun Mild Steel bowls while the raised ridges, base and logo are furnished in solid brushed brass.</p>
                    <p className="text-white text-base font-bold">36 books. 36 chambers. 36 pieces of Wu-Tang Clan legacy.</p>
                    </div>
                </div>
            </div>
        </section>
        </>
    )
}