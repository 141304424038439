
import './App.css';
import Header from './components/Header';
import Video from './components/Video';
import About from './components/About';
import ImageGallery from './components/ImageGallery';
import Footer from './components/Footer';
import VideoAd from './components/VideoAd';
function App() {
  return (
    <>
    <VideoAd/>
    <Video/>
    <Header/>
    <About/>
    <ImageGallery/>
    <Footer/>
    </>
  );
}

export default App;
