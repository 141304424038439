export default function Video(){
    return (
        <>
        <section className="bg-wuyellow py-12">
            <div className="max-w-7xl mx-auto text-center px-6 lg:px-0">
                <h3 className="text-3xl font-bold mb-4">"From conception to the present day, this is the story of the undisputed greatest Hip Hop group of all time being unveiled through rare and never before seen photos."</h3>
                <p className="text-xl">
                John "Mook" Gibbons
                </p>
                <p className="text-xl">
                Wu-Tang Clan Management
                </p>
                {/* <div className="aspect-w-16 aspect-h-9 mt-10">
                    <iframe key="Video" title="WuTang Legacy Book" src="https://www.youtube.com/embed/IuzGrUoXJ7w"  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                </div> */}
            </div>
        </section>
        </>
    )
}

//IuzGrUoXJ7w <-- RZA Video
// 01EE-bweGhY <-- Original