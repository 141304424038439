import ReactPlayer from 'react-player'
export default function VideoAd(){
    return (
        <>
        <section className="bg-black p-6 py-12">
            <div className='max-w-7xl px-0 mx-auto'>
                <div className="grid lg:grid-cols-2 gap-10 content-center">
                    <div className='text-white'>
                        <div className="relative pt-[56.25%]">
                            <ReactPlayer
                            url='https://www.youtube.com/watch?v=qEu60ZyOlT8'
                            className='absolute top-0 left-0'
                            playing
                            width='100%'
                            height='100%'
                            />
                        </div>
                    </div>
                    <div className='text-white px-0 text-center lg:text-left content-center grid'>
                        <h1 className='text-white text-4xl mb-4'>
                        <span className='text-wuyellow block'>Wutang Legacy Book and Chamber</span>
                        <a className='underline' href="https://wutanglegacy.artofpublishing.net/?form=true" target="_blank" rel="noopener noreferrer">Click here to Pre-Order Now</a>
                        </h1>
                            
                        
                        {/* https://wutanglegacy.artofpublishing.net/ */}
                            
                            {/* We apologize. Due to technical difficulties from an overwhelming response we will resume pre-orders on <span className='text-wuyellow'>Wu Wednesday, December 21, 2022, 3pm EST</span> */}
                        {/* <h1 className='text-white text-4xl mb-4'>If you are interested in purchasing the <span className='text-wuyellow'>WuTang Legacy Book and Chamber</span> please email <a href="mailto:wulegacy@wutangmanagement.com" className='underline'>wulegacy</a></h1>
                        <p className='mt-4 text-white text-xl md:text-xl mb-4'>Serious inquiries only.</p> */}
                    </div>
                </div>
            </div>
        </section>
        </>
    )
}