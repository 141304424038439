
import Slider from "react-slick";
import image_one from '../img/page-6.jpg';
import image_two from '../img/page-3.jpg';
import image_three from '../img/page-4.jpg';
import image_four from '../img/page-7.jpg';


export default function ImageGallery() {

    var settings = {
        dots: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        cssEase: "linear",
        pauseOnHover: true
      };

    const images = [image_one, image_two, image_three, image_four];

    //const selectedImage = images[Math.floor(Math.random()*images.length)];
    //bg-wuyellow
    
    return (
        <>
        <section className="bg-black py-6 md:py-12 overflow-hidden">
        <Slider {...settings}>
            {images.map((artpiece,index) => (
                <div key={index}>
                    <img src={artpiece} alt="WuTang Legacy Gallery" className='w-full h-auto' />
                </div>
            ))}
        </Slider>
            {/* <img src={selectedImage} alt="WuTang Legacy Gallery" className=' h-full w-auto md:w-full md:h-auto' /> */}
        </section>
        </>
    )
}