import MainImg from '../img/gallery-book-banner-b.jpg';
export default function Header(){
    return (
        <>
        <section className="bg-black">
            <div className="max-w-7xl mx-auto px-6 lg:px-0 py-12">
                <div className="grid md:grid-cols-2 md:gap-4 place-items-center">
                    <div className='mb-10 md:mb-0 text-center md:text-left'>
                    <h1 className='text-white text-3xl md:text-5xl mb-4'>Wu-Tang Clan: <span className='text-wuyellow'>Legacy</span></h1>
                    <h2 className='text-white text-xl md:text-2xl'>Presenting the biggest and rarest book in the history of Hip Hop</h2>
                    </div>
                    <div>
                        <img src={MainImg} alt="WuTang Legacy Book" className='md:ml-auto object-contain' />
                    </div>
                </div>
            </div>
        </section>
        </>
    )
}