import WuLogo from '../img/wu-logo.webp';
export default function Footer(){
    return (
        <>
        <section className="bg-black">
            <div className="max-w-5xl mx-auto px-6 lg:px-0 py-12 text-center">
                <div className='mb-10 md:mb-0 text-center'>
                    <img src={WuLogo} alt="Wu-Tang Logo" className='text-wuyellow w-28 h-auto mx-auto mb-4' />
                    <p className='text-white text-3xl md:text-5xl mb-4'>There will be only <span className='text-wuyellow'>36!</span></p>
                    <p className='mt-10 text-white'>© 2021 | All Rights Reserved. A <a href="https://www.kinake.com" title="Find out about the WuTang Legacy Book" rel="noopener noreferrer" target="_blank"><span className='text-wuyellow'>Kinake, LLC</span></a> Project</p>
                </div>
            </div>
        </section>
        </>
    )
}